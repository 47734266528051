import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import type {Languages as LanguagesGraphQL} from '@graphql-types';

// the plural is used to be consistent with the naming convention of the types generated from the GraphQL schema
export type Languages = Pick<
  LanguagesGraphQL,
  'code' | 'label' | 'name' | 'path'
>;

// the plural is used to be consistent with the naming convention of the types generated from the GraphQL schema
export interface Translations {
  href: string;
  lang: Languages;
}

const customPathLookup = {
  lookup: () => {
    let lookup = 'en-us';
    if (
      typeof window !== 'undefined' &&
      (window.location.pathname.startsWith('/es/') ||
        window.location.pathname === '/es')
    ) {
      lookup = 'es-mx';
    }
    return lookup;
  },

  name: 'esPathLookup',
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPathLookup);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // v4, the default, would require us to polyfill the Intl.PluralRules API
    // since we are not using plural key names, use the last version so we can have a smaller bundle
    compatibilityJSON: 'v3',
    detection: {
      order: ['esPathLookup'],
    },
    fallbackLng: {
      default: ['en-us'],
      es: ['es-mx'],
    },
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    missingKeyHandler: (lngs: string[], ns: string, key: string) => {
      const errMsg = `Missing ${lngs} translation for ${ns}:${key}`;
      if (window && window.DD_RUM) {
        window.DD_RUM.addError(new Error(errMsg));
      }
    },
    preload: ['en-us', 'es-mx'],
    react: {
      transKeepBasicHtmlNodesFor: ['b', 'br', 'i', 'li', 'p', 'strong', 'ul'],
      transSupportBasicHtmlNodes: true,
      useSuspense: false,
    },
    resources: require('../locales'),
  });

export default i18n;
